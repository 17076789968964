<template>
  <div>
    <b-tabs>
      <b-tab active title="Nhân Viên Đã Kích Hoạt">
        <b-table-filter
          :items="rows"
          :fields="fields"
          :filter="filter"
          :filter-on="filterOn"
          :is-busy="false"
          @to-edit="toEdit"
          title="Danh Sách Tài Khoản Đã Kích Hoạt"
        >
          <template #rightAction>
            <b-button :to="{name: 'staffs-create'}" variant="success" size="sm"><feather-icon icon="PlusIcon" /> Thêm Nhân Viên</b-button>
          </template>
          <template slot="filterTable">
            <td style="width: 15%">
                <b-form-input size="sm"
                  placeholder="Tên Nhân Viên"
                  @keyup="columnFilter('staff_name')"
                  v-model="filterKhoaHoc"
                />
            </td>
            <td style="width: 15%">
                <b-form-input size="sm"
                  placeholder="Tìm Số Điện Thoại"
                  @keyup="columnFilter('phone_number')"
                  v-model="filterLopHoc"
                  type="number"
                />
            </td>
            <td >
            </td>
            <td  style="width: 15%">
                <b-form-datepicker  size="sm"
                  v-model="filterDateFrom"
                  @input="columnFilter('tu_ngay')"
                  placeholder="Từ Ngày"
                  :date-format-options="{year: 'numeric', month: 'numeric', day: 'numeric'}"
                />
            </td>
            <td  style="width: 15%">
                <b-form-datepicker size="sm"
                  v-model="filterDateTo"
                  @input="columnFilter('den_ngay')"
                  placeholder="Đến Ngày"
                  :date-format-options="{year: 'numeric', month: 'numeric', day: 'numeric'}"
                  :date-disabled-fn="dateDisabled"
                />
            </td>
          </template>
        </b-table-filter>
      </b-tab>
      <b-tab title="Nhân Viên Chưa Kích Hoạt">
        <b-table-filter
          :items="rows"
          :fields="fields"
          :filter="filter"
          :filter-on="filterOn"
          :is-busy="false"
          @to-edit="toEdit"
          title="Danh Sách Tài Khoản Chưa Kích Hoạt"
        >
          <template #rightAction>
            <b-button :to="{name: 'staffs-create'}" variant="success" size="sm"><feather-icon icon="PlusIcon" /> Thêm Nhân Viên</b-button>
          </template>
          <template #filterTable>
            <td style="width: 15%">
                <b-form-input size="sm"
                  placeholder="Tên Nhân Viên"
                  @keyup="columnFilter('staff_name')"
                  v-model="filterKhoaHoc"
                />
            </td>
            <td style="width: 15%">
                <b-form-input size="sm"
                  placeholder="Tìm Số Điện Thoại"
                  @keyup="columnFilter('phone_number')"
                  v-model="filterLopHoc"
                  type="number"
                />
            </td>
            <td >
            </td>
            <td  style="width: 15%">
                <b-form-datepicker size="sm"
                  v-model="filterDateFrom"
                  @input="columnFilter('tu_ngay')"
                  placeholder="Từ Ngày"
                  :date-format-options="{year: 'numeric', month: 'numeric', day: 'numeric'}"
                />
            </td>
            <td  style="width: 15%">
                <b-form-datepicker size="sm"
                  v-model="filterDateTo"
                  @input="columnFilter('den_ngay')"
                  placeholder="Đến Ngày"
                  :date-format-options="{year: 'numeric', month: 'numeric', day: 'numeric'}"
                  :date-disabled-fn="dateDisabled"
                />
            </td>
          </template>
        </b-table-filter>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BFormInput, BCol, BRow, BFormGroup,
  BFormDatepicker, BButton, BFormCheckbox, BTabs, BTab,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VueSelect from 'vue-select'
import BTableFilter from '@core/components/datatable/BTableFilter.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
  methods: {
    toEdit(data) {
      this.$router.push({name: 'staffs-edit', params: { id: data.item.id}})
    },
    columnFilter() {
      console.log('columnFilter')
    },
  },
  components: {
    BFormCheckbox,
    VueSelect,
    BFormGroup,
    BCol,
    BRow,
    BTableFilter,
    BFormDatepicker,
    BFormInput,
    BButton,
    BTabs,
    BTab,
    FeatherIcon
},
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      fields: [
        {
          label: 'ID',
          key: 'id',
        },
        {
          label: 'Tên Nhân Viên',
          key: 'staff_name',
        },
        {
          label: 'Số Điện Thoại',
          key: 'phone_number',
        },
        {
          label: 'Ngày Sinh',
          sortable: true,
          key: 'birthday',
        },
        {
          label: 'Giới tính',
          sortable: true,
          key: 'gender',
        },
        {
          label: 'Quyền',
          width: '100px',
          sortable: true,
          key: 'role',
        },
        {
          label: 'Ngày kích hoạt',
          key: 'date_active',
        },
        {
          label: 'Ngày Tạo',
          key: 'created_at',
        },
        {
          label: 'Người Tạo',
          key: 'creator',
        },
        {
          label: 'Tác vụ',
          disableBtnDelete: true,
          key: 'act',
        },
      ],
      rows: [],
    }
  },
  created() {
    this.rows = [
      {
        id: 1,
        staff_name: 'Danh Sinh',
        phone_number: '0969985002',
        birthday: '09/12/1990',
        gender: 'male',
        role: 'admin',
        date_active: '20/03/1990',
        created_at: '20/03/1990',
        creator: 'Danh Sinh',
      }
    ]
  },
}
</script>
